import React, { useEffect, useState } from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
    faRemove,
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
    convertToMultiSelectComponentData,
    getDetailsByComponent,
    getDetailsByMake,
    getDetailsByModel,
    getDetailsByYear,
} from './MultiSelectDataHandling';
import { CollapsibleSection } from './CollapsableSection';
import { IItemListResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';
import {
    selectSearchValue,
    selectVehicleApplicationClicked,
    updateisMultiSelectFiltersApplied,
    updateMultiSelectFilters,
} from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks';
import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';

export interface VehicleData {
    make: Record<string, string[]>;
    model: Record<string, string[]>;
    year: Record<string, string[]>;
    component: Record<string, string[]>;
}

interface SubFiltersMenuProps {
    totalItems: IItemListResponse | undefined;
    onSale: boolean;
    newProduct: boolean;
    priceRange: any;
    selectedValues: SelectedValues;
    setSelectedValues: React.Dispatch<React.SetStateAction<SelectedValues>>;
    callForPricing: boolean;
    updatedFilterItems: IItemListResponse | undefined;
}
const CategorySection = ({
    selectedValues,
    expanded,
    toggleSection,
    handleItemClick,
    items,
}: {
    selectedValues: Record<string, string[]>;
    expanded: Record<string, boolean>;
    toggleSection: (label: string) => void;
    handleItemClick: any;
    items: {
        label: string;
        items: any[];
    }[];
}) => {
    const countSelectedItems = (
        items: { label: string; code: string; items?: any[] }[] | undefined,
        selectedCategories: string[]
    ): number => {
        if (!items || items.length === 0) {
            return 0;
        }

        return items.reduce((count, child) => {
            const isSelected = selectedCategories.includes(child.code) ? 1 : 0;
            const nestedCount = countSelectedItems(
                child.items,
                selectedCategories
            );
            return count + isSelected + nestedCount;
        }, 0);
    };

    const renderCategory = (category: { label: string; items: any[] }) => {
        return (
            <React.Fragment key={category.label}>
                <Box
                    sx={{
                        border: '1px solid gray',
                        borderRadius: '4px',
                        padding: '5px 10px',
                        width: 'auto',
                        textAlign: 'center',
                        flexBasis: '100%',
                        backgroundColor:
                            category.items.some((child: { code: string }) =>
                                selectedValues['category'].includes(child.code)
                            ) ||
                            selectedValues['categoryParents'].includes(
                                category.label
                            )
                                ? 'lightblue'
                                : 'white',
                        cursor: 'pointer',
                        textTransform: 'none',
                    }}
                    id={`filterButton_${category.label.replaceAll(' ', '')}`}
                    onClick={() => toggleSection(category.label)}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                            width: '100%',

                            wordBreak: 'break-word',
                        }}
                    >
                        {countSelectedItems(
                            category.items,
                            selectedValues['category']
                        ) > 0 && (
                            <Typography
                                sx={{
                                    border: '1px solid',
                                    borderRadius: '50%',
                                    minWidth: '30px',
                                    minHeight: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                }}
                            >
                                {countSelectedItems(
                                    category.items,
                                    selectedValues['category']
                                )}
                            </Typography>
                        )}

                        <Typography
                            id={`subcategoryMenuOption_${category.label.replaceAll(
                                ' ',
                                ''
                            )}`}
                        >
                            {category.label}
                        </Typography>
                        <FontAwesomeIcon
                            icon={
                                expanded[category.label]
                                    ? faChevronUp
                                    : faChevronDown
                            }
                        />
                    </Box>
                </Box>

                {expanded[category.label] && (
                    <Box
                        sx={{
                            padding: '10px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '10px',
                            backgroundColor: 'white',
                        }}
                    >
                        {category.items.map((child: any) => {
                            if (child.items && child.items.length > 0) {
                                return renderCategory(child);
                            }

                            return (
                                <Button
                                    key={String(child.label)}
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '4px',
                                        padding: '5px 10px',
                                        width: 'auto',
                                        textAlign: 'center',
                                        flexBasis: '100%',
                                        backgroundColor: selectedValues[
                                            'category'
                                        ].includes(child.code)
                                            ? 'lightblue'
                                            : 'white',
                                        cursor: 'pointer',
                                    }}
                                    id={`subcategoryMenuOption_${child.label}`}
                                    onClick={() =>
                                        handleItemClick('category', child.code)
                                    }
                                    startIcon={
                                        selectedValues['category'].includes(
                                            String(child.code)
                                        ) ? (
                                            <FontAwesomeIcon
                                                icon={faRemove}
                                                size="xs"
                                                style={{
                                                    fontSize: '10px',
                                                }}
                                            />
                                        ) : null
                                    }
                                >
                                    {child.label}
                                </Button>
                            );
                        })}
                    </Box>
                )}
            </React.Fragment>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
            }}
        >
            {items.map((category) => renderCategory(category))}
        </Box>
    );
};

export const findParentCategory = (categories: any[], value: string): any => {
    for (const category of categories) {
        if (
            category.items?.some(
                (item: { code: string }) => item.code === value
            )
        ) {
            return category;
        }

        if (category.items) {
            const found = findParentCategory(category.items, value);
            if (found) {
                return category;
            }
        }
    }
    return null;
};

export function transformCategories(
    allCategories: any,
    selectedCategories: any
) {
    const findCategoryHierarchy = (categories: any, code: any): any => {
        if (code == 'Essential Special Service Tools') {
            return {
                CategoryName: code,
                SubCategories: [],
            };
        }
        for (let category of categories) {
            if (category.code === code) {
                let result: { CategoryName: string; SubCategories: any[] } = {
                    CategoryName: category.label,
                    SubCategories: [],
                };
                if (category.items && category.items.length > 0) {
                    result.SubCategories =
                        buildHierarchy(category.items, selectedCategories) ||
                        [];
                }

                return result;
            }

            if (category.items) {
                const subCategoryHierarchy = findCategoryHierarchy(
                    category.items,
                    code
                );
                if (subCategoryHierarchy) {
                    return {
                        CategoryName: category.label,
                        SubCategories: [subCategoryHierarchy],
                    };
                }
            }
        }
        return null;
    };

    const buildHierarchy = (categories: any, selectedCategories: any[]) => {
        const result: any[] = [];
        selectedCategories.forEach((code: string) => {
            const categoryHierarchy = findCategoryHierarchy(categories, code);
            if (categoryHierarchy) {
                result.push(categoryHierarchy);
            }
        });
        return result;
    };

    return buildHierarchy(allCategories, selectedCategories);
}
interface UpdateFiltersParams {
    dispatchFilters: any;
    onSale: boolean;
    newProduct: boolean;
    priceRange: number[];
    allCategoriesWithChildren: any[];
    newSelectedValues: SelectedValues;
    callForPricing: boolean;
    totalSearchItems: IItemListResponse | undefined;
}

export const updateFilters = async (
    params: UpdateFiltersParams,
    isVehicleApplicationClicked: boolean
) => {
    const {
        dispatchFilters,
        onSale,
        newProduct,
        priceRange,
        allCategoriesWithChildren,
        newSelectedValues,
        callForPricing,
        totalSearchItems,
    } = params;
    if (!isVehicleApplicationClicked) {
        await dispatchFilters(updateisMultiSelectFiltersApplied(false));
        await dispatchFilters(updateMultiSelectFilters(null));

        if (
            Object.values(newSelectedValues).reduce(
                (acc, curr) => acc + curr.length,
                0
            ) !== 0 ||
            onSale ||
            newProduct ||
            callForPricing ||
            (priceRange[0] !== totalSearchItems?.minPrice &&
                priceRange[0] != -1) ||
            (priceRange[1] !== totalSearchItems?.maxPrice &&
                priceRange[1] != 1000000)
        ) {
            dispatchFilters(
                updateMultiSelectFilters({
                    OnSale: onSale,
                    NewProduct: newProduct,
                    MinPrice: priceRange[0] == -1 ? 0 : priceRange[0],
                    MaxPrice: priceRange[1] == 1000000 ? 0 : priceRange[1],
                    Categories: transformCategories(
                        allCategoriesWithChildren,
                        newSelectedValues.category
                    ),
                    Suppliers: newSelectedValues.supplier,
                    Makes: newSelectedValues.make,
                    Years: newSelectedValues.year,
                    Models: newSelectedValues.model,
                    Components: newSelectedValues.component,
                    callForPricing: callForPricing,
                })
            );
            dispatchFilters(updateisMultiSelectFiltersApplied(true));
        }
    }
};
export type SelectedValues = {
    make: string[];
    year: string[];
    model: string[];
    component: string[];
    supplier: string[];
    category: string[];
    categoryParents: string[];
};

const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { width };
};

export const SubFiltersMenu: React.FC<SubFiltersMenuProps> = ({
    totalItems,
    onSale,
    newProduct,
    priceRange,
    selectedValues,
    setSelectedValues,
    callForPricing,
    updatedFilterItems,
}) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const searchedValue = useAppSelector(selectSearchValue);
    const isVehicleApplicationClicked = useAppSelector(
        selectVehicleApplicationClicked
    );
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
    const [showAll, setShowAll] = useState({
        makes: false,
        years: false,
        models: false,
        components: false,
        category: false,
        supplier: false,
    });
    const [isMobileView, setIsMobileView] = useState<boolean>(false);
    const { width } = useWindowWidth();
    useEffect(() => {
        setIsMobileView(width <= 768);
    }, [width]);
    const [data, setData] = useState<VehicleData>({
        make: {},
        model: {},
        year: {},
        component: {},
    });

    useEffect(() => {
        setExpanded({});
        setShowAll({
            makes: false,
            years: false,
            models: false,
            components: false,
            category: false,
            supplier: false,
        });
        setData({
            make: {},
            model: {},
            year: {},
            component: {},
        });
        setSelectedValues({
            make: [],
            year: [],
            model: [],
            component: [],
            supplier: [],
            category: [],
            categoryParents: [],
        });
    }, [totalItems, searchedValue]);
    const MultiSelectData = convertToMultiSelectComponentData(
        updatedFilterItems?.refineCatalogs
    );
    const makes = MultiSelectData.vehicles
        .map((v) => v.make)
        .sort((a, b) => a.localeCompare(b));
    const yearsSet = new Set<string>();
    MultiSelectData.vehicles.forEach((vehicle) => {
        vehicle.years.forEach((yearObj) => {
            yearsSet.add(yearObj.year);
        });
    });
    const allYears = Array.from(yearsSet).sort((a, b) => a.localeCompare(b));

    const modelsSet = new Set<string>();
    MultiSelectData.vehicles.forEach((vehicle) => {
        vehicle.years.forEach((yearObj) => {
            yearObj.models.forEach((modelObj) => {
                modelsSet.add(modelObj.model);
            });
        });
    });
    const allModels = Array.from(modelsSet).sort((a, b) => a.localeCompare(b));

    const extractComponents = (modelObj: any): string[] => modelObj.components;
    const extractModels = (yearObj: any): string[] =>
        yearObj.models.flatMap(extractComponents);
    const extractYears = (vehicle: any): string[] =>
        vehicle.years.flatMap(extractModels);
    const getAllComponents = (vehicles: any[]): Set<string> => {
        const componentsSet = new Set<string>();
        vehicles.forEach((vehicle) => {
            extractYears(vehicle).forEach((component) => {
                componentsSet.add(component);
            });
        });
        return componentsSet;
    };

    const componentsSet = getAllComponents(MultiSelectData.vehicles);

    const allComponents = Array.from(componentsSet).sort((a, b) =>
        a.localeCompare(b)
    );

    const allSuppliers =
        Array.from(
            new Set(
                updatedFilterItems?.suppliers?.map((supplier) => supplier.label)
            )
        ).sort((a, b) => a.localeCompare(b)) ?? [];

    const allCategoriesWithChildren =
        updatedFilterItems?.childrenCatalogs
            ?.map((category) => ({
                label: category.label,
                items: category.items || [],
            }))
            .sort() ?? [];
    const toggleSection = (section: any) => {
        setExpanded((prev) => ({ ...prev, [section]: !prev[section] }));
    };
    const toggleShowMore = (section: keyof typeof showAll) => {
        setShowAll((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    const extractLastLabels = (items: any[]) => {
        let labels: any[] = [];
        items.forEach(
            (item: { items: string | any[]; label: any; code: string }) => {
                if (item.items && item.items.length > 0) {
                    if (Array.isArray(item.items)) {
                        labels = labels.concat(extractLastLabels(item.items));
                    }
                } else {
                    labels.push(item.code);
                }
            }
        );
        return labels;
    };

    const setCategoryLabels = (parentLabel: string | null) => {
        if (parentLabel != null && parentLabel != undefined) {
            const parentCategory = allCategoriesWithChildren.find(
                (parent: { label: any }) => parent.label === parentLabel
            );
            if (parentCategory) {
                const lastLabels = extractLastLabels(parentCategory.items);

                setSelectedValues((prevState) => ({
                    ...prevState,
                    category: [
                        ...prevState.category,
                        ...(lastLabels.length > 0
                            ? lastLabels
                            : [parentCategory.label]),
                    ],
                    categoryParents: [
                        ...prevState.categoryParents,
                        parentLabel,
                    ],
                }));
            }
        }
    };
    useEffect(() => {
        if (searchedValue) {
            setCategoryLabels(searchedValue);
        }
    }, [totalItems]);

    useEffect(() => {
        if (
            Object.values(selectedValues).reduce(
                (acc, curr) => acc + curr.length,
                0
            ) === 0
        ) {
            if (!isVehicleApplicationClicked) {
                dispatch(updateMultiSelectFilters(null));
                dispatch(updateisMultiSelectFiltersApplied(false));
            }
            // dispatch(updateVehicleApplicationClicked(false))
            if (!isVehicleApplicationClicked) {
                dispatch(updateMultiSelectFilters(null));
                dispatch(updateisMultiSelectFiltersApplied(false));
            }
            // dispatch(updateVehicleApplicationClicked(false))
        }
    }, [selectedValues]);
    const handleItemClick: (
        type: 'make' | 'model' | 'year' | 'component' | 'supplier' | 'category',
        value: string,
        getDetailsFunction?: (value: string, refineCatalogs: any) => any
    ) => Promise<void> = async (
        type: 'make' | 'model' | 'year' | 'component' | 'supplier' | 'category',
        value: string,
        getDetailsFunction?: (value: string, refineCatalogs: any) => any
    ) => {
        const isSelected = selectedValues[type].includes(value);
        let newSelectedValues;
        if (type === 'category') {
            const parentCategory = findParentCategory(
                allCategoriesWithChildren,
                value
            );

            const firstLabel = parentCategory?.label ?? null;

            if (isSelected) {
                newSelectedValues = {
                    ...selectedValues,
                    [type]: selectedValues[type].filter(
                        (item) => item !== value
                    ),
                    categoryParents: selectedValues['categoryParents'].filter(
                        (item) => item !== firstLabel
                    ),
                };
            } else {
                newSelectedValues = {
                    ...selectedValues,
                    [type]: [...selectedValues[type], value],
                    categoryParents: firstLabel
                        ? [
                              ...(selectedValues['categoryParents'] || []),
                              firstLabel,
                          ]
                        : selectedValues['categoryParents'] || [],
                };
            }
        } else {
            newSelectedValues = isSelected
                ? {
                      ...selectedValues,
                      [type]: selectedValues[type].filter(
                          (item) => item !== value
                      ),
                  }
                : {
                      ...selectedValues,
                      [type]: [...selectedValues[type], value],
                  };
        }
        updateFilters(
            {
                dispatchFilters: dispatch,
                onSale,
                newProduct,
                priceRange,
                allCategoriesWithChildren,
                newSelectedValues,
                callForPricing,
                totalSearchItems: totalItems,
            },
            !!isVehicleApplicationClicked
        );

        setSelectedValues(newSelectedValues);
        if (getDetailsFunction && !isSelected) {
            const details = getDetailsFunction(
                value,
                updatedFilterItems?.refineCatalogs
            );

            setData((prevData) => ({
                make: {
                    ...prevData.make,
                    ...(type !== 'make' && {
                        [value]: details.makes,
                    }),
                },
                model: {
                    ...prevData.model,
                    ...(type !== 'model' && {
                        [value]: details.models,
                    }),
                },
                year: {
                    ...prevData.year,
                    ...(type !== 'year' && {
                        [value]: details.years,
                    }),
                },
                component: {
                    ...prevData.component,

                    ...(type !== 'component' && {
                        [value]: details.components,
                    }),
                },
            }));
        } else {
            const { [value]: removedMake, ...remainingMake } = data.make;
            const { [value]: removedModel, ...remainingModels } = data.model;
            const { [value]: removedYear, ...remainingYears } = data.year;
            const { [value]: removedComponent, ...remainingComponents } =
                data.component;

            setData(() => ({
                make: remainingMake,
                model: remainingModels,
                year: remainingYears,
                component: remainingComponents,
            }));
        }
    };

    function getProductFilterOptionStyles(
        data: VehicleData,
        selectedValues: any,
        dataValue: any,
        itemDataObject: any
    ) {
        const isEmpty = Object.values(data).every(
            (value) => Object.keys(value).length === 0
        );

        const isSelected = Object.values(selectedValues).some(
            (categoryValues: any) => categoryValues.includes(dataValue)
        );
        const isDataTypeIncluded = Object.values(itemDataObject).some(
            (makes: any) => makes.includes(dataValue)
        );

        let backgroundColor = 'lightgrey';
        let isDisabled = true;

        if (isSelected) {
            backgroundColor = 'lightblue';
            isDisabled = false;
        } else if (
            isDataTypeIncluded ||
            isEmpty ||
            Object.keys(itemDataObject).length == 0
        ) {
            backgroundColor = 'white';
            isDisabled = false;
        }

        return { backgroundColor, isDisabled, isSelected };
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenPopover = (event: any) => {
        setAnchorEl(event.currentTarget);
        toggleSection('category');
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        toggleSection('category');
    };

    const isPopoverOpen = Boolean(anchorEl);

    return (
        <Box>
            {makes.length > 0 && (
                <CollapsibleSection
                    title={translate(`facetedSearch.labels.facetname_makes`)}
                    sectionData={makes}
                    expanded={expanded}
                    toggleSection={toggleSection}
                    handleClick={(item: string) =>
                        handleItemClick('make', item, getDetailsByMake)
                    }
                    getProductFilterOptionStyles={getProductFilterOptionStyles}
                    showAll={showAll}
                    toggleShowMore={() => toggleShowMore('makes')}
                    sectionKey="makes"
                    sectionMappedData={data.make}
                    completeMappedFiltersData={data}
                    selectedValues={selectedValues}
                    selectedSectionLength={selectedValues.make.length}
                />
            )}
            {allYears.length > 0 && (
                <CollapsibleSection
                    title={translate(`facetedSearch.labels.facetname_year`)}
                    sectionData={allYears}
                    expanded={expanded}
                    toggleSection={toggleSection}
                    handleClick={(item: string) =>
                        handleItemClick('year', item, getDetailsByYear)
                    }
                    getProductFilterOptionStyles={getProductFilterOptionStyles}
                    showAll={showAll}
                    toggleShowMore={() => toggleShowMore('years')}
                    sectionKey="years"
                    sectionMappedData={data.year}
                    completeMappedFiltersData={data}
                    selectedValues={selectedValues}
                    selectedSectionLength={selectedValues.year.length}
                />
            )}
            {allModels.length > 0 && (
                <CollapsibleSection
                    title={translate(`facetedSearch.labels.facetname_models`)}
                    sectionData={allModels}
                    expanded={expanded}
                    toggleSection={toggleSection}
                    handleClick={(item: string) =>
                        handleItemClick('model', item, getDetailsByModel)
                    }
                    getProductFilterOptionStyles={getProductFilterOptionStyles}
                    showAll={showAll}
                    toggleShowMore={() => toggleShowMore('models')}
                    sectionKey="models"
                    sectionMappedData={data.model}
                    completeMappedFiltersData={data}
                    selectedValues={selectedValues}
                    selectedSectionLength={selectedValues.model.length}
                />
            )}
            {allComponents.length > 0 && (
                <CollapsibleSection
                    title={'Components'}
                    sectionData={allComponents}
                    expanded={expanded}
                    toggleSection={toggleSection}
                    handleClick={(item: string) =>
                        handleItemClick(
                            'component',
                            item,
                            getDetailsByComponent
                        )
                    }
                    getProductFilterOptionStyles={getProductFilterOptionStyles}
                    showAll={showAll}
                    toggleShowMore={() => toggleShowMore('components')}
                    sectionKey="components"
                    sectionMappedData={data.component}
                    completeMappedFiltersData={data}
                    selectedValues={selectedValues}
                    selectedSectionLength={selectedValues.component.length}
                />
            )}

            <>
                {allCategoriesWithChildren.length > 0 && (
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            borderRadius: '4px',
                            textAlign: 'center',
                            border: '1px solid gray',
                            marginTop: '15px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '5px 10px',
                            cursor: 'pointer',
                            backgroundColor:
                                selectedValues['category'].length > 0
                                    ? 'lightblue'
                                    : 'white',
                        }}
                        onClick={handleOpenPopover}
                    >
                        {selectedValues['category'].length > 0 && (
                            <Typography
                                sx={{
                                    border: '1px solid',
                                    borderRadius: '50%',
                                    minWidth: '30px',
                                    minHeight: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                }}
                            >
                                {selectedValues['category'].length}
                            </Typography>
                        )}
                        <Typography id={'categoryMenuOption_Categories'}>
                            {translate(
                                `facetedSearch.labels.facetname_category`
                            )}
                        </Typography>
                        <FontAwesomeIcon
                            icon={
                                expanded.category
                                    ? faChevronLeft
                                    : faChevronRight
                            }
                        />
                    </Box>
                )}

                <Popover
                    open={isPopoverOpen}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: isMobileView ? 'top' : 'center',
                        horizontal: isMobileView ? 'center' : 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: isMobileView ? 'center' : 'left',
                    }}
                    sx={{
                        marginLeft: '10px',
                        zIndex: isMobileView ? 1300 : 10,
                    }}
                    disableScrollLock={true}
                >
                    <Box
                        sx={{
                            width: '300px',
                            padding: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            border: '1px solid gray',
                            borderRadius: '8px',
                            backgroundColor: 'white',
                        }}
                    >
                        {showAll.category ? (
                            <CategorySection
                                selectedValues={selectedValues}
                                expanded={expanded}
                                toggleSection={toggleSection}
                                handleItemClick={handleItemClick}
                                items={allCategoriesWithChildren}
                            />
                        ) : (
                            <CategorySection
                                selectedValues={selectedValues}
                                expanded={expanded}
                                toggleSection={toggleSection}
                                handleItemClick={handleItemClick}
                                items={allCategoriesWithChildren.slice(0, 6)}
                            />
                        )}

                        {allCategoriesWithChildren.length > 6 && (
                            <Button
                                onClick={() => toggleShowMore('category')}
                                style={{
                                    marginTop: 1,
                                    fontSize: '10px',
                                    fontWeight: '900',
                                    color: 'blue',
                                }}
                            >
                                {showAll['category'] ? 'less' : 'more'}
                            </Button>
                        )}
                    </Box>
                </Popover>
            </>

            {allSuppliers.length > 0 && (
                <Box
                    style={{
                        position: 'relative',
                        width: '100%',
                        borderRadius: '4px',
                        textAlign: 'center',
                        border: '1px solid gray',
                        marginTop: '15px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '5px 10px',
                        cursor: 'pointer',
                        textTransform: 'none',
                        backgroundColor:
                            selectedValues.supplier.length > 0
                                ? 'lightblue'
                                : 'white',
                    }}
                    onClick={() => toggleSection('supplier')}
                >
                    {selectedValues.supplier.length > 0 && (
                        <Typography
                            sx={{
                                border: '1px solid',
                                borderRadius: '50%',
                                minWidth: '30px',
                                minHeight: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                fontSize: '12px',
                            }}
                        >
                            {selectedValues.supplier.length}
                        </Typography>
                    )}

                    <Typography id={'supplierMenuOption_Suppliers'}>
                        {translate(`ltl_SupplierFacetText`)}
                    </Typography>
                    <FontAwesomeIcon
                        icon={expanded.supplier ? faChevronUp : faChevronDown}
                    />
                </Box>
            )}

            {(allSuppliers?.length ?? 0) > 0 && expanded.supplier && (
                <Box
                    style={{
                        marginTop: '10px',
                    }}
                >
                    {showAll.supplier ? (
                        <Box
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                                maxHeight: '400px',
                                overflowY: 'auto',
                                padding: '10px',
                            }}
                        >
                            {allSuppliers.map((supplier) => (
                                <Button
                                    key={supplier}
                                    id={`supplierFilterBtn_${supplier.replaceAll(
                                        ' ',
                                        ''
                                    )}`}
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '4px',
                                        padding: '5px 10px',
                                        width: 'auto',
                                        textAlign: 'center',
                                        backgroundColor: selectedValues[
                                            'supplier'
                                        ].includes(String(supplier))
                                            ? 'lightblue'
                                            : 'white',
                                        cursor: 'pointer',
                                        textTransform: 'none',
                                    }}
                                    startIcon={
                                        selectedValues['supplier'].includes(
                                            supplier
                                        ) ? (
                                            <FontAwesomeIcon
                                                icon={faRemove}
                                                size="xs"
                                                style={{ fontSize: '10px' }}
                                            />
                                        ) : null
                                    }
                                    onClick={() =>
                                        handleItemClick('supplier', supplier)
                                    }
                                >
                                    {supplier}
                                </Button>
                            ))}
                        </Box>
                    ) : (
                        <Box
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                                padding: '0',
                            }}
                        >
                            {allSuppliers.slice(0, 6).map((supplier) => (
                                <Button
                                    key={supplier}
                                    id={`supplierFilterBtn_${supplier.replaceAll(
                                        ' ',
                                        ''
                                    )}`}
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '4px',
                                        padding: '5px 10px',
                                        width: 'auto',
                                        textAlign: 'center',
                                        backgroundColor: selectedValues[
                                            'supplier'
                                        ].includes(supplier)
                                            ? 'lightblue'
                                            : 'white',
                                        cursor: 'pointer',
                                        textTransform: 'none',
                                    }}
                                    startIcon={
                                        selectedValues['supplier'].includes(
                                            supplier
                                        ) ? (
                                            <FontAwesomeIcon
                                                icon={faRemove}
                                                size="xs"
                                                style={{ fontSize: '10px' }}
                                            />
                                        ) : null
                                    }
                                    onClick={() =>
                                        handleItemClick('supplier', supplier)
                                    }
                                >
                                    {supplier}
                                </Button>
                            ))}
                        </Box>
                    )}
                </Box>
            )}

            {allSuppliers.length > 6 && expanded.supplier && (
                <Button
                    onClick={() => toggleShowMore('supplier')}
                    style={{
                        marginTop: 1,
                        fontSize: '10px',
                        fontWeight: '900',
                        color: 'blue',
                    }}
                >
                    {showAll.supplier ? 'less' : 'more'}
                </Button>
            )}
        </Box>
    );
};
