import { IItemListResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';

interface SliderDataResponse {
    minPrice: number | null;
    maxPrice: number | null;
    onSaleProducts: boolean;
    newProducts: boolean;
    callForPricingProducts: boolean;
}

export const sliderItemsData = (
    updatedFilterItems: IItemListResponse | undefined, totalSearchItems: IItemListResponse | undefined
): SliderDataResponse => {
    let onSaleProducts: boolean = false;
    let newProducts: boolean = false;
    let callForPricing: boolean = false;
    updatedFilterItems?.newProductPromo?.forEach((promo) => {
        if (promo.key == 'On_Sale') {
            onSaleProducts = true;
        } else if (promo.key == 'New_Products') {
            newProducts = true;
        }
    });
    const minPrice = totalSearchItems?.minPrice ?? null;
    const maxPrice = totalSearchItems?.maxPrice ?? null;
    const checkForCallForPricingOnUpdateFilters =
        updatedFilterItems?.minPrice ?? null;
    if (checkForCallForPricingOnUpdateFilters == 0) {
        callForPricing = true;
    }
    return {
        minPrice,
        maxPrice,
        newProducts: newProducts,
        onSaleProducts: onSaleProducts,
        callForPricingProducts: callForPricing,
    };
};
