import {
    faChevronUp,
    faChevronDown,
    faRemove,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SelectedValues, VehicleData } from './AdditionalFiltersMenuComponent';

interface CollapsibleSectionProps {
    title: string;
    sectionData: string[];
    expanded: { [key: string]: boolean };
    toggleSection: React.Dispatch<React.SetStateAction<string>>;
    handleClick: (item: string) => void;
    getProductFilterOptionStyles: (
        allData: VehicleData,
        selectedValues: SelectedValues,
        item: string,
        dataObject: Record<string, string[]>
    ) => { backgroundColor: string; isDisabled: boolean; isSelected: boolean };
    showAll: { [key: string]: boolean };
    toggleShowMore: () => void;
    sectionKey: string;
    sectionMappedData: Record<string, string[]>;
    completeMappedFiltersData: VehicleData;
    selectedValues: SelectedValues;
    selectedSectionLength: number;
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
    title,
    sectionData,
    expanded,
    toggleSection,
    handleClick,
    getProductFilterOptionStyles,
    showAll,
    toggleShowMore,
    sectionKey,
    sectionMappedData,
    completeMappedFiltersData,
    selectedValues,
    selectedSectionLength,
}) => {
    const handleToggleSection = () => {
        toggleSection(sectionKey);
    };

    const handleShowMoreClick = () => {
        toggleShowMore();
    };

    const handleItemClick = (item: string) => {
        handleClick(item);
    };

    const renderItems = (items: any[]) => {
        return items.map((item, index) => {
            const { backgroundColor, isDisabled, isSelected } =
                getProductFilterOptionStyles(
                    completeMappedFiltersData,
                    selectedValues,
                    item,
                    sectionMappedData
                );

            return (
                <Button
                    key={item}
                    id={`${sectionKey.toLowerCase()}FilterBtn_${item.replaceAll(
                        ' ',
                        ''
                    )}`}
                    disabled={isDisabled}
                    className="chip-button"
                    style={{
                        border: '1px solid gray',
                        borderRadius: '4px',
                        padding: '5px 10px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: 'rgb(33, 37, 41)',
                        backgroundColor: backgroundColor,
                        textTransform: 'none',
                        display: 'inline-block',
                        width: 'auto',
                    }}
                    onClick={() => handleItemClick(item)}
                    startIcon={
                        isSelected ? (
                            <FontAwesomeIcon
                                icon={faRemove}
                                size="xs"
                                style={{
                                    fontSize: '10px',
                                    display: 'inline-block',
                                }}
                            />
                        ) : null
                    }
                >
                    {item}
                </Button>
            );
        });
    };

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    borderRadius: '4px',
                    textAlign: 'center',
                    border: '1px solid gray',
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px 10px',
                    cursor: 'pointer',
                    backgroundColor:
                        selectedSectionLength > 0 ? 'lightblue' : 'white',
                }}
                onClick={handleToggleSection}
            >
                {selectedSectionLength > 0 && (
                    <Typography
                        sx={{
                            border: '1px solid',
                            borderRadius: '50%',
                            minWidth: '30px',
                            minHeight: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            fontSize: '12px',
                        }}
                    >
                        {selectedSectionLength}
                    </Typography>
                )}
                <Typography
                    id={`${sectionKey.toLowerCase()}MenuOption_${sectionKey.replaceAll(
                        ' ',
                        ''
                    )}`}
                >
                    {title}
                </Typography>
                <FontAwesomeIcon
                    icon={expanded[sectionKey] ? faChevronUp : faChevronDown}
                />
            </Box>

            {expanded[sectionKey] && (
                <Box
                    sx={{
                        marginTop: '10px',
                        wordBreak: 'break-all',
                    }}
                >
                    {showAll[sectionKey] ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                                maxHeight: '500px',
                                overflowY: 'auto',
                                marginLeft: '20px',
                                marginRight: '20px',
                            }}
                        >
                            {renderItems(sectionData)}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                                marginLeft: '20px',
                                marginRight: '20px',
                            }}
                        >
                            {renderItems(sectionData.slice(0, 6))}
                        </Box>
                    )}
                </Box>
            )}

            {sectionData.length > 6 && expanded[sectionKey] && (
                <Button
                    onClick={handleShowMoreClick}
                    style={{
                        marginTop: 1,
                        fontSize: '10px',
                        fontWeight: '900',
                        color: 'blue',
                    }}
                >
                    {showAll[sectionKey] ? 'less' : 'more'}
                </Button>
            )}
        </>
    );
};
