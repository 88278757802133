import { useEffect, useRef, useState } from 'react';
import { Box, Button, Slider, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
    faRemove,
} from '@fortawesome/free-solid-svg-icons';
import { sliderItemsData } from './sliderItemsData';
import {
    SelectedValues,
    SubFiltersMenu,
    updateFilters,
} from './AdditionalFiltersMenuComponent';
import { IItemListResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks';
import {
    selectSearchValue,
    selectVehicleApplicationClicked,
} from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice';
import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';
const MultiSelectList = ({
    totalSearchItems,
    updatedFilterItems,
}: {
    totalSearchItems: IItemListResponse | undefined;
    updatedFilterItems: IItemListResponse | undefined;
}) => {
    const [visibility, setVisibility] = useState({
        showSlider: false,
        showMake: false,
        showYear: false,
        showModel: false,
        showComponent: false,
        showCategory: false,
        showSupplier: false,
    });
    const [selectedValues, setSelectedValues] = useState<SelectedValues>({
        make: [],
        year: [],
        model: [],
        component: [],
        supplier: [],
        category: [],
        categoryParents: [],
    });
    const {
        minPrice,
        maxPrice,
        onSaleProducts,
        newProducts,
        callForPricingProducts,
    } = sliderItemsData(updatedFilterItems, totalSearchItems);
    const [isOnSaleClicked, setIsOnSaleClicked] = useState<boolean>(false);
    const [isNewClicked, setIsNewClicked] = useState<boolean>(false);
    const isVehicleApplicationClicked = useAppSelector(
        selectVehicleApplicationClicked
    );
    const isProgrammaticUpdate = useRef(false);
    const { translate } = useTranslate();
    const [isCallForPricingClicked, setIsCallForPricingClicked] =
        useState<boolean>(false);
    const dispatch = useDispatch();
    const [price, setPrice] = useState<number[]>([
        minPrice ?? -1,
        maxPrice ?? 1000000,
    ]);
    const [finalPrice, setFinalPrice] = useState<number[]>([
        minPrice ?? -1,
        maxPrice ?? 1000000,
    ]);
    const handleSaleClick = () => {
        setIsOnSaleClicked(!isOnSaleClicked);
    };

    const allCategoriesWithChildren =
        totalSearchItems?.childrenCatalogs?.map((category) => ({
            label: category.label,
            items: category.items || [],
        })) ?? [];
    const handleNewClick = () => {
        setIsNewClicked(!isNewClicked);
    };
    const handleCallForPricingClick = () => {
        setIsCallForPricingClicked(!isCallForPricingClicked);
    };

    const toggleVisibility = (key: keyof typeof visibility) => {
        setVisibility((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    useEffect(() => {
        if (isProgrammaticUpdate.current) {
            isProgrammaticUpdate.current = false;
            return;
        }
        updateFilters(
            {
                dispatchFilters: dispatch,
                onSale: isOnSaleClicked,
                newProduct: isNewClicked,
                priceRange: finalPrice,
                allCategoriesWithChildren,
                newSelectedValues: selectedValues,
                callForPricing: isCallForPricingClicked,
                totalSearchItems: totalSearchItems,
            },
            !!isVehicleApplicationClicked
        );
    }, [isOnSaleClicked, isNewClicked, finalPrice, isCallForPricingClicked]);
    const searchedValue = useAppSelector(selectSearchValue);

    useEffect(() => {
        setIsOnSaleClicked(false);
        setIsNewClicked(false);
        setIsCallForPricingClicked(false);
        setPrice([-1, 1000000]);
        setFinalPrice([-1, 1000000]);
        isProgrammaticUpdate.current = true;
        if (searchedValue === 'On Sale') {
            setIsOnSaleClicked(true);
        } else if (searchedValue === 'NewProducts') {
            setIsNewClicked(true);
        }
    }, [totalSearchItems, searchedValue]);

    return (
        <Box style={{ padding: 2, marginTop: '1rem' }}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {onSaleProducts && (
                    <Button
                        style={{
                            width: '40%',
                            borderRadius: 5,
                            marginRight: '10px',
                            textAlign: 'center',
                            border: '1px solid gray',
                            cursor: 'pointer',
                            backgroundColor: isOnSaleClicked
                                ? 'lightblue'
                                : 'white',
                            color: 'rgb(33, 37, 41)',
                            textTransform: 'none',
                        }}
                        id="onSaleFilterBtn"
                        onClick={handleSaleClick}
                        startIcon={
                            isOnSaleClicked ? (
                                <FontAwesomeIcon
                                    icon={faRemove}
                                    size="xs"
                                    style={{
                                        fontSize: '10px',
                                        marginLeft: '5px',
                                    }}
                                />
                            ) : null
                        }
                    >
                        {translate(`On_Sale_FacetText`)}
                    </Button>
                )}
                {newProducts && (
                    <Button
                        onClick={() => handleNewClick()}
                        style={{
                            width: '40%',
                            borderRadius: 5,
                            marginRight: '10px',
                            textAlign: 'center',
                            border: '1px solid gray',
                            cursor: 'pointer',
                            backgroundColor: isNewClicked
                                ? 'lightblue'
                                : 'white',
                            color: 'rgb(33, 37, 41)',
                            textTransform: 'none',
                        }}
                        id="newFilterBtn"
                        startIcon={
                            isNewClicked ? (
                                <FontAwesomeIcon
                                    icon={faRemove}
                                    size="xs"
                                    style={{
                                        fontSize: '10px',
                                        marginLeft: '5px',
                                    }}
                                />
                            ) : null
                        }
                    >
                        {translate(`CMSNewText`)}
                    </Button>
                )}
            </Box>

            {minPrice != null && maxPrice != null && maxPrice > 0 && (
                <Box
                    style={{
                        position: 'relative',
                        width: '100%',
                        borderRadius: '5px',
                        textAlign: 'center',
                        border: '1px solid gray',
                        marginTop: '15px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '5px 10px',
                        cursor: 'pointer',
                    }}
                    onClick={() => toggleVisibility('showSlider')}
                >
                    <Typography id={'priceOption_Price'}>
                        {translate(`ltl_PriceFacetText`)}
                    </Typography>
                    {visibility.showSlider ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                    )}
                </Box>
            )}
            {visibility.showSlider && (minPrice || maxPrice) && (
                <Box>
                    {!isCallForPricingClicked && (
                        <Box
                            style={{
                                marginTop: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                                width: 'auto',
                                padding: '15px 15px 0',
                            }}
                        >
                            <Typography
                                style={{ textAlign: 'center', padding: '5px' }}
                            >{`${
                                totalSearchItems?.items[0]?.price
                                    ?.currencySymbol
                                    ? totalSearchItems?.items[0]?.price
                                          ?.currencySymbol
                                    : '$'
                            }${
                                Number.isInteger(minPrice)
                                    ? minPrice?.toFixed(0)
                                    : minPrice?.toFixed(2)
                            }`}</Typography>
                            <Slider
                                value={price}
                                onChange={(
                                    event: Event,
                                    newValue: number | number[]
                                ) => {
                                    if (
                                        Array.isArray(newValue) &&
                                        (newValue[0] !== finalPrice[0] ||
                                            newValue[1] !== finalPrice[1])
                                    ) {
                                        setPrice(newValue);
                                    }
                                }}
                                onChangeCommitted={(
                                    event: Event,
                                    newValue: number | number[]
                                ) => {
                                    if (
                                        Array.isArray(newValue) &&
                                        (newValue[0] !== finalPrice[0] ||
                                            newValue[1] !== finalPrice[1])
                                    ) {
                                        setFinalPrice(newValue);
                                    }
                                }}
                                valueLabelDisplay="auto"
                                min={Number(minPrice?.toFixed(2))}
                                max={Number(maxPrice?.toFixed(2))}
                                style={{ flex: 1 }}
                            />
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    marginLeft: '5px',
                                }}
                            >{`${
                                totalSearchItems?.items[0]?.price
                                    ?.currencySymbol
                                    ? totalSearchItems?.items[0]?.price
                                          ?.currencySymbol
                                    : '$'
                            }${
                                Number.isInteger(maxPrice)
                                    ? maxPrice?.toFixed(0)
                                    : maxPrice?.toFixed(2)
                            }`}</Typography>
                        </Box>
                    )}

                    {callForPricingProducts && (
                        <Button
                            onClick={() => handleCallForPricingClick()}
                            style={{
                                border: '1px solid',
                                textTransform: 'none',
                                width: '150px',
                                backgroundColor: isCallForPricingClicked
                                    ? 'lightblue'
                                    : 'white',
                                borderRadius: 5,
                                marginTop: 10,
                            }}
                            id="callForPricingFilterBtn"
                            startIcon={
                                isCallForPricingClicked ? (
                                    <FontAwesomeIcon
                                        icon={faRemove}
                                        size="xs"
                                        style={{ fontSize: '10px' }}
                                    />
                                ) : null
                            }
                        >
                            {translate(`MissingPrice`)}
                        </Button>
                    )}
                </Box>
            )}
            <SubFiltersMenu
                totalItems={totalSearchItems}
                onSale={isOnSaleClicked}
                newProduct={isNewClicked}
                priceRange={price}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                callForPricing={isCallForPricingClicked}
                updatedFilterItems={updatedFilterItems}
            />
        </Box>
    );
};

export default MultiSelectList;
